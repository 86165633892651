<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            List {{ pageTitle }}
          </h2>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            Action
            <feather-icon icon="ChevronDownIcon" />
          </b-button>
        </template>

        <b-dropdown-item v-for="action in actionTop" v-bind:key="action.name" @click="listAction(action)">
          <feather-icon
            :icon="action.icon"
            size="16"
          />
          <span class="align-middle ml-50">{{ action.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <global-form :data="globalFormData" :loadData="loadData" ref="globalForm"></global-form>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BDropdown, BDropdownItem, BButton, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import GlobalForm from '@/views/components/modal/GlobalForm.vue'

export default {
  data() {
    return {
      pageTitle: '',
      actionTop: [],
      globalFormData: {},
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    GlobalForm,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  watch: {
    /* eslint-disable */
    '$route': function(to, from) {
      this.pageTitle = ''
      this.settingConfig()
    },
    /* eslint-enable */
  },
  created() {
    this.settingConfig()
  },
  methods: {
    settingConfig() {
      /* eslint-disable */
      var str = window.location.href
      const conf = str.split('/')
      this.$http.get(conf[4] + '/' + conf[5] +`/config`)
        .then(res => {
          this.pageTitle = res.data.PageTitle
          this.actionTop = res.data.ActionTopButton
          })
      /* eslint-enable */
    },
  },
  listAction(data) {
    if (data.type === 'GlobalForm') this.showGlobalForm(data)
  },
  showGlobalForm(data) {
    /* eslint-disable */
      let dataform = {}
      let forms = data.form
      let modal = data
      if(typeof data.get !== 'undefined') {
        this.$http.get(data.get).then(res => {
          forms.map((value,key) => {
            dataform[value.fieldToSave] = res.data[value.fieldToSave]
          })

          this.globalFormData = { form: forms, data: dataform, modal: modal }
          this.$refs.globalForm.$children[0].show()
        })
      } else {
        this.globalFormData = { form: forms, data: {}, modal: modal }
        this.$refs.globalForm.$children[0].show()
      }
      /* eslint-enable */
  },
}
</script>
