<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="notifCount > 0"
        :badge="notifCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <feather-icon
        v-else
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
          v-if="notifCount > 0"
        >
          {{ notifCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Account Notification -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link @click="clickHandler(notif)" v-for="notif in notifications" v-bind:key="notif.date">
        <b-media class="mb-2">
          <template #aside>
            <b-avatar
              size="32"
              :src="notif.avatar"
              :text="notif.avatar"
              :variant="notif.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder" v-if="!notif.read">
              {{ notif.title }} 🔴
            </span>
            <span class="font-weight-thin" v-else>
              {{ notif.title }}
            </span>
          </p>
          <small>{{ notif.subtitle }}</small> <br>
          <small class="notification-text">From {{ notif.from }} at {{ notif.date }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="readAllNotifications"
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
/* eslint-disable */
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifCount: 0,
      notifications: [],
      useNativeNotification: false,
    }
  },
  created() {
    this.storeNotification()
    this.watchNotification()  
    this.requestNotification()
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  methods: {
    requestNotification() {
      this.$notification.requestPermission().then(res => {
        if(res === 'granted') this.useNativeNotification = true
        else this.useNativeNotification = false
      })
    },

    watchNotification() {
      const user = JSON.parse(localStorage.getItem('userData'))
      let ini = this
      let channel = this.$pusher.subscribe('notification')
      channel.bind('App\\Events\\NotificationSend',function(data) {
        const msg = data.message
        if(msg.user === user.Oid) {
          ini.notifications.unshift(msg)
          ini.notifCount = parseInt(ini.notifCount) + 1
          if(ini.useNativeNotification === true) ini.nativeNotification(msg)
        }
      })
    },

    nativeNotification(data) {
      const notification = {
        title: data.title,
        options: {
          body: data.subtitle,
          icon: 'https://app.divideindonesia.com/logo.png',
        },
        events: {
          onclick: function () {
            window.location = data.url
          }
        }
      }
      this.$notification.show(notification.title, notification.options, notification.events)
    },
    
    storeNotification() {
      this.$Progress.start()
      this.$http.get('general/init/notification')
        .then(res => {
          this.notifCount = res.data.unread
          this.notifications = res.data.notifications
          this.$Progress.finish()
        })
        .catch((error) => {
          this.$store.state.error = 'Error : '+error.response.data
          this.$Progress.fail()
        })
    },

    clickHandler(notif) {
      this.$Progress.start()
      this.$http.post(`general/init/notification/${notif.oid}`)
        .then(res => {
          this.$Progress.finish()
          window.location = notif.url
        })
    },

    readAllNotifications() {
      this.$Progress.start()
      this.$http.post('general/init/notification')
        .then(res => {
          this.storeNotification()
          this.$Progress.finish()
        })
    }
  },
}
/* eslint-enable */
</script>

<style>

</style>
