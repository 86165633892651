/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
/* eslint-disable */
// import navMenu from './nav-menu'
// import axios from 'axios'
// axios.get(localStorage.getItem('apiUrl') + '/general/init/menu', {
//     headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } 
// })
//     .then(res => localStorage.setItem('menu',JSON.stringify(res.data)))

// Array of sections
// export default [...navMenu, ...JSON.parse(localStorage.getItem('menu'))]
export default []
/* eslint-enable */
