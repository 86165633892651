<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
/* eslint-disable */
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },

  data() {
    return {
      items: {},
    }
  },

  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },

  created() {
    this.loadMenu()
  },

  methods: {
    
    loadMenu() {
      this.loaderState(true)
      const dashboard = {
        title: 'Dashboard',
        route: {
          name: 'dashboard',
          fullPath: '/dashboard',
        },
        icon: 'HomeIcon',
      }

      const endpoint = localStorage.getItem('apiUrl') + '/general/init/menu'
      this.$http.get(endpoint)
      .then(res => {
        this.items = res.data
        this.items.unshift(dashboard)
        this.items.unshift({header: 'Dashboard'})
        this.loaderState(false)
      })
      .catch(err => this.loaderState(false))
    }

  },
}
</script>
