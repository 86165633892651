<template>
    <b-modal
        @shown="loadForm"
        @ok="handleOk"
        id="globalFormModal"
        ref="globalFormModal"
        ok-title="Submit"
        centered
        title="Global Form"
        :size="size"
        no-close-on-backdrop
        v-model="globalFormShow"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
            <b-form-group
                v-for="field in fields"
                v-bind:key="field.fieldToSave"
                :class="'col-md-'+field.column"
            >
                <label v-if="field.type !== 'checkbox' && field.type !== 'labeltext'" :for="field.fieldToSave+'-input'">{{ field.label }}</label>
                <b-form-textarea
                    v-if="field.type === 'textarea'"
                    :placeholder="field.placeholder"
                    :id="field.fieldToSave+'-input'"
                    :state="formState[field.fieldToSave]"
                    :name="field.fieldToSave"
                    rows="5"
                    v-model="formData[field.fieldToSave]"
                />
                <b-form-checkbox
                    v-else-if="field.type === 'checkbox'"
                    v-model="checkboxes[field.fieldToSave]"
                    :id="field.fieldToSave+'-input'"
                    :name="field.fieldToSave"
                    value="check"
                    unchecked-value="uncheck"
                >
                    {{ field.label }}
                </b-form-checkbox>
                <b-form-datepicker
                    v-else-if="field.type === 'date'"
                    :id="field.fieldToSave+'-input'"
                    :name="field.fieldToSave"
                    :state="formState[field.fieldToSave]"
                    :placeholder="field.placeholder"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="id"
                    class="mb-2"
                    v-model="formData[field.fieldToSave]"
                />
                <b-form-timepicker
                    v-else-if="field.type === 'time'"
                    :id="field.fieldToSave+'-input'"
                    :name="field.fieldToSave"
                    :placeholder="field.placeholder"
                    :state="formState[field.fieldToSave]"
                    v-model="formData[field.fieldToSave]"
                />
                <vue-autosuggest
                    v-else-if="field.type === 'comboautocomplete' && Object.keys(data.data).length > 0"
                    ref="autocomplete"
                    v-model="comboShowing[field.fieldToSave]"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                      id: field.fieldToSave+'-input',
                      placeholder: field.placeholder,
                      class:(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid',
                      autocomplete:'off',
                    }"
                    @input="comboStore(field.store, field.fieldToSave)"
                    @focus="comboFocus(field.fieldToSave)"
                    @click="comboSelect(field.fieldToSave)"
                    @selected="comboSelectHandler"
                    :get-suggestion-value="comboGetSuggestionValue"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                </vue-autosuggest>
                <vue-autosuggest
                    v-else-if="field.type === 'comboautocomplete' && Object.keys(data.data).length < 1"
                    ref="autocomplete"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                      id: field.fieldToSave+'-input',
                      placeholder: field.placeholder,
                      class:(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid',
                      autocomplete:'off',
                    }"
                    @input="comboStore(field.store, field.fieldToSave)"
                    @click="comboSelect(field.fieldToSave)"
                    @selected="comboSelectHandler"
                    :get-suggestion-value="comboGetSuggestionValue"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                </vue-autosuggest>
                <vue-autosuggest
                    v-else-if="field.type === 'combosource' && Object.keys(data.data).length > 0 && comboSelected[field.fieldToSave] !== undefined"
                    v-model="combo[field.fieldToSave].data[getComboSourceSelectedIndex(field.fieldToSave)].Name"
                    ref="combosource"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                      id: field.fieldToSave+'-input',
                      placeholder: field.placeholder,
                      class:(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid',
                      autocomplete:'off',
                    }"
                    @click="comboSelect(field.fieldToSave)"
                    @selected="comboSelectHandler"
                    :get-suggestion-value="comboGetSuggestionValue"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                </vue-autosuggest>
                <vue-autosuggest
                    v-else-if="field.type === 'combosource' && Object.keys(data.data).length > 0"
                    ref="combosource"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                      id: field.fieldToSave+'-input',
                      placeholder: field.placeholder,
                      class:(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid',
                      autocomplete:'off',
                    }"
                    @click="comboSelect(field.fieldToSave)"
                    @selected="comboSelectHandler"
                    :get-suggestion-value="comboGetSuggestionValue"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                </vue-autosuggest>
                <vue-autosuggest
                    v-else-if="field.type === 'combosource' && Object.keys(data.data).length < 1"
                    ref="combosource"
                    :suggestions="[combo[field.fieldToSave]]"
                    :input-props="{
                      id: field.fieldToSave+'-input',
                      placeholder: field.placeholder,
                      class:(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid',
                      autocomplete:'off',
                    }"
                    @click="comboSelect(field.fieldToSave)"
                    @selected="comboSelectHandler"
                    :get-suggestion-value="comboGetSuggestionValue"
                >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        {{ suggestion.item.Name }}
                    </div>
                </vue-autosuggest>
                <cleave
                    v-else-if="field.numberFormat === true"
                    :id="field.fieldToSave+'-input'"
                    :state="formState[field.fieldToSave]"
                    v-model="formData[field.fieldToSave]"
                    :class="(formState[field.fieldToSave]) ? 'form-control  is-valid' : 'form-control is-invalid'"
                    :raw="true"
                    :options="{
                        numeral: true,
                    }"
                    :placeholder="field.placeholder"
                    autocomplete="off"
                    :name="field.fieldToSave"
                />
                <span v-else-if="field.type === 'labeltext'" class="font-weight-bold">{{ field.label }}</span>
                <b-form-file
                    v-else-if="field.type === 'file'"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="fileSelected"
                    :name="field.fieldToSave"
                    required
                ></b-form-file>
                <b-form-input
                    v-else
                    :type="(field.type === 'password') ? 'password' : 'text'"
                    :placeholder="field.placeholder"
                    :id="field.fieldToSave+'-input'"
                    :state="formState[field.fieldToSave]"
                    autocomplete="off"
                    :name="field.fieldToSave"
                    v-model="formData[field.fieldToSave]"
                />
            </b-form-group>
        </div>
      </form>
    </b-modal>
</template>

<script>
/* eslint-disable */
import { BModal, BCardText, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormDatepicker, BFormTimepicker, BFormFile } from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
    name: 'GlobalForm',
    props: ['data','afterRequest','globalFormShow','closeGlobalForm'],
    components: {
        BCardText,
        BModal,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BFormDatepicker,
        BFormTimepicker,
        VueAutosuggest,
        Cleave,
        BFormFile,
    },
    data() {
        return {
            size: '',
            activeCombo: '',
            activeFile: '',
            combo: {},
            checkboxes: {},
            comboSelected: {},
            comboShowing: {},
            fields: {},
            formData: {},
            formState: {},
        }
    },
    methods: {
        loadForm() {
            this.size = ''
            this.activeCombo = ''
            this.combo = {}
            this.comboSelected = {}
            this.comboShowing = {}
            this.fields = {}
            this.formData = {}
            this.checkboxes = {}
            const now = new Date()
            const currentDate = moment(now).format('Y-M-D')

            if(typeof this.data.modal.size !== 'undefined') this.size = this.data.modal.size
            this.$Progress.start()
            this.data.form.map((value,key) => {
                if(value.type === 'comboautocomplete') {
                    let tmp = { data: [] }
                    if(Object.keys(this.data.data).length > 0 && typeof this.data.data[value.fieldToSave] !== 'undefined') {
                        tmp = { data: [this.data.data[value.fieldToSave]] }
                        this.comboSelected[value.fieldToSave] = this.data.data[value.fieldToSave].Oid
                        this.$set(this.comboShowing, value.fieldToSave, this.data.data[value.fieldToSave].Name)
                    } else this.comboSelected[value.fieldToSave] = undefined
                    this.$set(this.combo, value.fieldToSave, tmp)
                } else if(value.type === 'combosource') {
                    let tmp = { data: value.source }
                    if(Object.keys(this.data.data).length > 0 && typeof this.data.data[value.fieldToSave] !== 'undefined') {
                        tmp = { data: this.data.data[value.fieldToSave].source }
                        this.comboSelected[value.fieldToSave] = this.data.data[value.fieldToSave].selected.Oid
                    } else this.comboSelected[value.fieldToSave] = undefined
                    this.$set(this.combo, value.fieldToSave, tmp)
                }
                
                if(Object.keys(this.data.data).length > 0 && typeof this.data.data[value.fieldToSave] !== 'undefined') {
                    let formValue
                    if(this.data.data[value.fieldToSave] == '' || this.data.data[value.fieldToSave] == null || typeof this.data.data[value.fieldToSave] == 'undefined') formValue = undefined
                    else {
                        if(value.type === 'comboautocomplete') formValue = this.data.data[value.fieldToSave].Oid
                        else if (value.type === 'combosource') formValue = this.data.data[value.fieldToSave].selected.Oid
                        else if (value.type === 'checkbox') {
                            if(this.data.data[value.fieldToSave] === 1) this.checkboxes[value.fieldToSave] = 'check'
                            else this.checkboxes[value.fieldToSave] = 'uncheck'
                            formValue = this.data.data[value.fieldToSave]
                        } else formValue = this.data.data[value.fieldToSave]
                    }

                    this.$set(this.formData, value.fieldToSave, formValue)
                } else {
                    if(value.type === 'checkbox') {
                        this.$set(this.checkboxes, value.fieldToSave, 'uncheck')
                        this.$set(this.formData, value.fieldToSave, 0)
                    } else {
                        if(typeof value.defaultValue !== 'undefined'){
                            if(value.defaultValue === 'NOW()'){
                                this.$set(this.formData, value.fieldToSave, currentDate)
                            } else this.$set(this.formData, value.fieldToSave, value.defaultValue)
                        } else this.$set(this.formData, value.fieldToSave, undefined)
                    }
                }

                this.$set(this.formState, value.fieldToSave, '')
            })
            
            if(typeof this.data.data.Oid !== 'undefined') this.$set(this.formData, 'Oid', this.data.data.Oid)

            this.fields = this.data.form
            this.handleState()
            this.$Progress.finish()
        },

        getComboSourceSelectedIndex(fieldToSave) {
            let index = 0
            let selectedIndex
            this.combo[fieldToSave].data.map((value,key) => {
                if(value.Oid === this.comboSelected[fieldToSave]) selectedIndex = index
                else index++
            })

            return selectedIndex
        },

        handleState() {
            let validate = true
            this.data.form.map((value, key) => {
                if(value.required === 1) {
                    if(typeof this.formData[value.fieldToSave] == 'undefined' || this.formData[value.fieldToSave] == '') {
                        validate = false
                        this.formState[value.fieldToSave] = false
                    } else this.formState[value.fieldToSave] = true
                } else this.formState[value.fieldToSave] = true
            })

            return validate
        },

        handleOk(e) {
            e.preventDefault()
            let validate = this.handleState()
            if(validate) this.handleSubmit()
        },
        handleSubmit() {
            let body = new FormData()
            const self = this

            this.data.form.map((value,key) => {
                if(value.numberFormat === true) {
                    const replace = this.formData[value.fieldToSave].replace(',','')
                    body.append(value.fieldToSave, parseFloat(replace))
                } else if(value.type === 'checkbox') {
                    let val
                    if(this.checkboxes[value.fieldToSave] === 'check') val = 1
                    else val = 0
                    body.append(value.fieldToSave, val)
                } else if(value.type === 'file') {
                    body.append(value.fieldToSave, this.formData[value.fieldToSave], this.formData[value.fieldToSave].name)
                } else {
                    body.append(value.fieldToSave, this.formData[value.fieldToSave])
                }
            })
            
            this.$http.post(this.data.modal.post, body )
                .then(res => {
                    if(typeof this.data.modal.afterRequest !== 'undefined') this.afterRequest(this.data.modal.afterRequest, res.data)
                    self.closeGlobalForm()
                })
        },
        
        comboStore(storeUrl, fieldToSave) {
            let keyword = document.getElementById(fieldToSave+'-input').value
            if(keyword.length < 3) {
                this.combo[fieldToSave] = { data: [], raw: [] }
            } else {
                this.comboAutoComplete(storeUrl, keyword, fieldToSave)
            }
        },

        comboAutoComplete(storeUrl, keyword, fieldToSave) {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                this.$Progress.start()
                this.$http.get(`${storeUrl}?keyword=${keyword}`)
                .then(res => {
                    const tmp = { data: res.data }
                    this.combo[fieldToSave] = tmp
                    this.$Progress.finish()
                })
            }, 1000)
        },
        
        comboFocus(fieldToSave) {
            this.comboShowing[fieldToSave] = ''
            document.getElementById(fieldToSave+'-input').value = ''
        },

        comboSelect(fieldToSave) {
            this.activeCombo = fieldToSave
        },

        comboSelectHandler(item) {
            this.comboSelected[this.activeCombo] = item.item.Oid
            this.formData[this.activeCombo] = item.item.Oid
        },

        comboGetSuggestionValue(selected) {
            return selected.item.Name
        },

        fileSelected(event) {
            this.formData[event.srcElement.name] = event.target.files[0]
        },
    },
}
/* eslint-enable */
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
